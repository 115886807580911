import React from 'react';
import logo from './pixel-lex-stand.png';
import './App.css';

function App() {
  // var client_id = 'c8dbc1801c6e4517aeb51e9e11d4ba51'; // Your client id
  // var client_secret = '2ff8828a992140c4a42092fa1f328c6e'; // Your secret
  // var redirect_uri = 'http://localhost:3000/callback'; // Your redirect uri
  // var scopes = 'user-read-private user-read-email';

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="lexy.dev" />
        <h1>lexy.dev</h1>
      </header>

      <p>In which our hero learns to use React</p>
    </div>
  );
}

export default App;
